import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {Box, Button, Container, Grid, Icon, Paper, Typography} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {UserContext} from "../App";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface CourseOverviewProps {
    courses?: any;
}

export const CourseOverview = (props: CourseOverviewProps) => {

    const {user, setUser} = useContext(UserContext);
    const [courses, setCourses] = useState<Array<any>>([])

    useEffect(() => {
        setCourses(props.courses)
    })

    const renderAddress = (address: any) => {
        if (!address.name) {
            return <b>Nicht bekannt</b>
        } else {
            return <p>Ort: {address.name}</p>
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h1">Kursplan 2024</Typography>
            </Grid>
            {courses.map((element: any, index: number) => (
                <Grid container key={index}>
                    <Typography id={element[1][0]['course_template']['name']}
                                variant="h2">{element[1][0]['course_template']['name']}</Typography>
                    <Grid container key={element}>
                        {element[1].map((course: any, index: number) => (
                            <Grid item xs={12} md={6} key={index}>
                                <Paper elevation={4}
                                       sx={{marginRight: "1rem", marginBottom: "1rem", paddingBottom: "1rem"}}>
                                    <span style={{padding: '1rem'}}>{course.participants}% belegt.</span>
                                    <LinearProgress value={course.participants} variant="determinate"/>
                                    <div style={{
                                        padding: '1rem'
                                    }}>
                                        <h4 style={{color: '#CA2B2B', margin: 0}}>
                                            {`${course.course_template.name} (${course.course_nr})`}
                                        </h4>
                                        <span style={{fontSize: '0.75rem'}}>
                                        {`${course.comment}`}
                                        </span>
                                    </div>
                                    <Grid container style={{padding: '0 1rem 1rem 1rem', alignItems: 'center'}}>
                                        <AccessTimeIcon style={{marginRight: '5px'}}/>
                                        {`${new Date(course.start_date).toLocaleDateString("de")} bis
                                                                            ${new Date(course.end_date).toLocaleDateString("de")}`}
                                        <LocationOnIcon style={{margin: '0 5px 0 5px'}}/>
                                        <div>
                                            {renderAddress(course.address)}
                                        </div>
                                    </Grid>
                                    <Box style={{padding: '1rem'}}>
                                        {course.course_template.educational_goals}
                                    </Box>
                                    <Box sx={{margin: "1rem"}}>
                                        <Button variant={"text"} style={{color: "#CA2B2B"}}
                                                endIcon={<ArrowForwardIosIcon/>} component={Link}
                                                to={{pathname: `/kurs/details/${course.course_template.alias}`}}>
                                            Mehr Erfahren
                                        </Button>
                                        {user ?
                                            <Button variant={"contained"} endIcon={<ArrowForwardIosIcon/>}
                                                    sx={{marginLeft: "1rem"}} component={Link}
                                                    to={{pathname: `/buchung/${course.course_nr}`}}>
                                                Buchen
                                            </Button> : ""}
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}
