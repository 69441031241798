import React, {ReactFragment, useContext, useEffect, useState} from "react";
import {UserContext} from "../App";
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import CloseIcon from '@mui/icons-material/Close';
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import {Link} from "react-router-dom";
import {Event, Logout, PersonAdd, Settings} from "@mui/icons-material";
import {LoginService} from "../user/LoginService";


interface HeaderProps {

}


export const Header = (props: HeaderProps) => {
    const {user} = useContext(UserContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [toggleMobileNav, setMobileNav] = useState(false);
    const [isMobileWith, setIsMobileWith] = useState<boolean>(false);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setIsMobileWith(window.innerWidth <= 900);
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleMobileNavigation = (): any => {
        console.log(toggleMobileNav);
        setMobileNav(!toggleMobileNav);
    }
    const userHasCourseMgmRoles = () => {
        if (user) {
            return user.roles.filter((role: any) => {
                return (role.course_templates.length > 0 || role.courses.length > 0)
            }).length > 0
        } else {
            return false;
        }
    }

    const handleLogout = async (event: any) => {
        event.preventDefault();
        const loginService = new LoginService()
        const logout = async () => {
            await loginService.logout();
            window.location.assign("/")
        }
        logout().catch(console.error)
    }

    return (
        <>
            <Grid item xs={6} md={8}>
                <Box>
                    <img src={require("../images/wappen.png")} alt="Wappen Landkreis Roth."
                         style={{maxHeight: '12rem', width: 'auto', marginTop: '1rem'}}/>
                </Box>
            </Grid>
            <Grid item xs={6} md={4}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 1,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    <MenuItem onClick={handleClose}>
                        <Avatar/>
                        <Button variant={"text"} style={{color: "#000"}}
                                component={Link}
                                to={{pathname: `/profil`}}>
                            Profil
                        </Button>
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Event fontSize="small"/>
                        </ListItemIcon>
                        <Button variant={"text"} style={{color: "#000"}}
                                component={Link}
                                to={{pathname: `/buchungen`}}>
                            Buchungen
                        </Button>
                    </MenuItem>
                    {userHasCourseMgmRoles() ? (
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Event fontSize="small"/>
                            </ListItemIcon>

                            <Button variant={"text"} style={{color: "#000"}}
                                    component={Link}
                                    to={{pathname: `/admin/kurse`}}>
                                Lehrgangsverwaltung
                            </Button>
                        </MenuItem>) : ("")
                    }
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Logout fontSize="small"/>
                        </ListItemIcon>
                        <Button variant={"text"} style={{color: "#000"}} onClick={handleLogout}>
                            Logout
                        </Button>
                    </MenuItem>
                </Menu>
                <Box className="landingNavContainer"
                     style={{display: (toggleMobileNav || !isMobileWith) ? 'flex' : 'none'}}
                     sx={{alignItems: 'center', textAlign: 'center'}}>

                    <Link to="/">Startseite</Link>
                    <Link to="/kurse">Lehrgänge</Link>
                    {user ? (
                        <Tooltip title="Mein Profil">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ml: 2}}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >

                                <Avatar sx={{
                                    width: 32,
                                    height: 32,
                                    backgroundColor: "orange"
                                }}>{(user.username).substring(0, 1).toUpperCase()}</Avatar>
                            </IconButton>
                        </Tooltip>) : <Link to="/login">Login</Link>}
                </Box>
                <Box id="mobileNav" style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div id="mobileNavIcon" onClick={toggleMobileNavigation} >
                        <div>
                            {toggleMobileNav ? (
                                <CloseIcon style={{fontSize: '5rem',  color: 'gray'}}/>
                            ) : (
                                <DensityMediumIcon style={{fontSize: '3rem', color: 'gray'}}/>
                            )}
                        </div>

                    </div>
                </Box>
            </Grid>
        </>
    )
}
