import React from "react";
import {Autocomplete, Box, Button, Collapse, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';
import {CourseService} from "../services/CourseService";
import {Link} from "react-router-dom";
import {CourseCalendar} from "./CourseCalendarComponent";
import {CourseOverview} from "./CourseOverviewComponent";
import "./CoursePage.css";
import {Download, ExpandLess, ExpandMore, Filter, FilterAlt, Send} from "@mui/icons-material";
import {Header} from "../common/Header";

interface CoursesPageProps {
    courseService: CourseService
}

interface FilterState {
    open: boolean,
    year: boolean,
    courses: any
}

// Change Filter to a more complex filter option and handle the courses using types!!
interface CoursesPageState {
    courses?: any;
    coursesCalendar?: any
    loading: boolean
    filter: FilterState

}

export class CoursesPage extends React.Component<CoursesPageProps, CoursesPageState> {
    constructor(props: CoursesPageProps) {
        super(props);
        this.state = {
            courses: [],
            coursesCalendar: [],
            filter: {open: false, year: false, courses: []},
            loading: true

        };
    }

    private month = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "November", "Dezember"]

    async componentDidMount() {
        await this.loadCourses();
    }


    // work on a better inital course handling interface.
    async loadCourses() {
        try {
            const json1: any = await this.props.courseService.getCoursesByYear(2023);
            this.setState({coursesCalendar: Object.entries(json1.courses), loading: true})
            const json2: any = await this.props.courseService.getCourses();
            this.setState({
                courses: Object.entries(json2.courses),
                loading: false,
                filter: {
                    ...this.state.filter,
                    courses: json2.courses.map((course: any) => (course[0].course_template.name))
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    handleFilterCalendarSwitch = async () => {
        // this.setState( {filter: {open: false, year: !this.state.filter.year}, loading: true})
        this.setState({filter: {...this.state.filter, year: !this.state.filter.year}, loading: true})
        await this.loadCourses();
    }

    // Render Output handling the loading state of the component.
    renderList = () => {
        if (!this.state.loading) {
            if (this.state.filter.year) {
                return <CourseCalendar courses={this.state.coursesCalendar}/>
            } else {
                return <CourseOverview courses={this.state.courses}/>
            }
        } else {
            return <p>Laden...</p>
        }
    }

    handleFilterToggleClick = () => {
        this.setState({filter: {...this.state.filter, open: !this.state.filter.open}})
    }

    render() {
        return (
            <Grid container maxWidth={'lg'} sx={{margin: 'auto'}}>
                <Header/>
                <Grid item xs={12} md={12} sx={{padding: '0 1rem 0 1rem'}}>
                    <Button variant="contained"
                            sx={{marginRight: "1rem"}}
                            startIcon={<FilterAlt />}
                            endIcon={this.state.filter.open ? <ExpandLess/> : <ExpandMore /> }
                            onClick={this.handleFilterToggleClick}>
                        Filter
                    </Button>
                    <Link to={require('../downloads/Lehrgangskatalog-LK Roth_2024.pdf')} target={'_blank'}>
                        <Button variant="text" endIcon={<Download/>}>
                            PDF-Version herunterladen
                        </Button>
                    </Link>

                    <Collapse in={this.state.filter.open} timeout="auto" unmountOnExit sx={{marginTop: "0.5rem"}}>
                        <Paper elevation={1} sx={{padding: "1rem"}}>
                            <Typography variant="subtitle1">Lehrgangsart suchen:</Typography>
                            <Autocomplete
                                disablePortal
                                options={this.state.filter.courses}
                                sx={{width: "100%"}}
                                onChange={(event: any, value: any) => {
                                    const headline = document.getElementById(value);
                                    headline && headline.scrollIntoView({behavior: "smooth", block: "start"})
                                }}
                                renderInput={(params) => <TextField {...params} label="Lehrgang"/>}
                            />
                            <Typography variant="subtitle1" sx={{paddingTop: "1rem"}} >Weitere Filter:</Typography>
                            <Button onClick={this.handleFilterCalendarSwitch}>nach Monaten / Arten</Button>
                        </Paper>
                    </Collapse>
                    {this.renderList()}
                </Grid>
            </Grid>
        );
    }
}
